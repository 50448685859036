import { DOCUMENT, isPlatformServer } from '@angular/common';
import { DestroyRef, Injectable, InjectionToken, LOCALE_ID, OnDestroy, PLATFORM_ID, inject } from '@angular/core';
import { combineLatest, ReplaySubject } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import { UserConsentService } from './user-consent.service';
import { PerformanceConsentCategory } from './one-trust.service';
import { isPageWithCountries, LanguageUrls, Page } from '../types/page';
import { PageService } from './page.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface MappSettings {
  mappTiDomain: string;
  mappTiId: string;
}

export interface MappConfiguration {
  enabled: boolean;
  production: boolean;
}

export const mappConfigurationToken = new InjectionToken<MappConfiguration>('mapp-configuration');

// https://documentation.mapp.com/1.0/en/how-to-send-manual-tracking-requests-page-updates-7240681.html

// A page request is simulated.
declare type PageRequest = ['send', 'page', {
  contentId: string;
  internalSearch?: string;
}];
// Clicking a link causes a new page load.
declare type LinkEvent = ['send', 'link', {
  linkId: string;
}];
// If you want to measure actions on your page where the current page is not left.
declare type ClickEvent = ['send', 'click', {
  linkId: string;
}];
// With the three variants mentioned above, only the passed parameters in the object are sent. This means that the parameters configured via Tag Integration and plugins are not checked and executed.

// If the content on your page has changed and information configured in Tag Integration should also be sent.
// With every "pageupdate" all rules and plugins are checked and executed. The existing pixel configuration is extended with new parameters and rules.
declare type PageUpdateRequest = ['send', 'pageupdate'];

declare type TrackingEvent = PageRequest | LinkEvent | ClickEvent | PageUpdateRequest;

declare const wts: {
  push: (request: TrackingEvent) => void;
  get: (attributeName: string) => unknown;
}

declare const window: {
  _tiConfig?: {
    customDomain?: string;
    customPath?: string;
    tiDomain?: string;
    tiId?: string;
    option?: {
      url?: string;
    };
  };
  kbDataLayer?: {
    page?: {
      name: string;
      title: string;
      language: string;
      country?: string;
      campaignId?: string;
    };
    campaign?: {
      id: string;
      name: string;
    };
    internalSearch?: {
      query: string;
      resultCount: number;
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class MappService implements OnDestroy {

  private document = inject(DOCUMENT);
  private platformId = inject(PLATFORM_ID);
  private settingsService = inject(SettingsService);
  private pageService = inject(PageService);
  private configuration = inject(mappConfigurationToken);
  private userConsentService = inject(UserConsentService);
  private destroyRef = inject(DestroyRef);
  private localeId = inject(LOCALE_ID);

  private userConsent = this.userConsentService.forCategory(PerformanceConsentCategory);
  private trackingRequest$ = new ReplaySubject<TrackingEvent>(); // intentional infinite buffer size

  public ngOnDestroy() {
    this.trackingRequest$.complete();
  }

  public init() {
    if (!this.configuration.enabled) {
      return;
    }
    if (isPlatformServer(this.platformId)) {
      return;
    }

    combineLatest([
      this.settingsService.mappSettingData,
      this.userConsent.given$.pipe(filter(given => given === true)),
    ]).pipe(
      take(1),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(([settings]) => {
      this.loadMapp(settings).then(() => {
        this.trackingRequest$.pipe(
          takeUntilDestroyed(this.destroyRef),
        ).subscribe((trackingRequest) => {
          wts.push(trackingRequest);
        });
      });
    });

    this.pageService.page.pipe(
      switchMap((page) => this.userConsent.given$.pipe(
        filter((consentGiven) => consentGiven === true),
        take(1),
        map(() => page),
      )),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((page) => {
      this.trackPageView(page);
    });
  }

  private loadMapp(settings: MappSettings): Promise<Event> {
    if (!this.configuration.production) {
      window._tiConfig = window._tiConfig || {
        // Needed because of test & staging ports
        customDomain: 'responder.wt-safetag.com',
        customPath: 'resp/api/get/777749083302574?custom=1',
        option: {url: 'test-knorr-bremse.com'},
      };
    } else {
      window._tiConfig = window ._tiConfig || {
        tiDomain: settings.mappTiDomain,
        tiId: settings.mappTiId,
        option: {},
      };
    }

    const scriptElement = this.document.createElement('script');
    scriptElement.defer = true;
    scriptElement.async = true;
    scriptElement.src = `/${this.localeId}/assets/js/tiLoader.min.js`;
    scriptElement.type = 'text/javascript';

    return new Promise<Event>((resolve, reject) => {
      scriptElement.onload = resolve;
      scriptElement.onerror = reject;
      this.document.head.appendChild(scriptElement);
    });
  }

  public trackInternalSearch(query: string, resultCount: number): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    window.kbDataLayer = {
      internalSearch: {
        query: query,
        resultCount: resultCount,
      },
    };
    this.trackingRequest$.next(['send', 'pageupdate']);
  }

  public trackClick(name: string, parameters?: { [key: string]: unknown }): void {
    this.trackingRequest$.next(['send', 'click', {
      linkId: name,
      ...parameters,
    }]);
  }

  private trackPageView(page: Page): void {
    combineLatest([
      this.settingsService.fallbackLanguage,
      this.settingsService.fallbackCountry,
    ]).pipe(
      take(1),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(([fallbackLanguage, fallbackCountry]) => {
      let languageUrls: LanguageUrls;
      let pageCountry;
      if (isPageWithCountries(page)) {
        pageCountry = page.currentCountry;

        if (fallbackCountry) {
          languageUrls = page.countryLanguageCombinations[fallbackCountry];
        } else {
          const firstRegion = Object.keys(page.countryLanguageCombinations)[0];
          languageUrls = page.countryLanguageCombinations[firstRegion];
        }
      } else {
        languageUrls = page.languages;
      }

      const localeIndependentPageUrl = languageUrls[fallbackLanguage] ?? languageUrls[Object.keys(languageUrls)[0]];
      const localeIndependentPageUrlParts = localeIndependentPageUrl.split('/');
      localeIndependentPageUrlParts.splice(1, 1); // remove language
      if (isPageWithCountries(page)) {
        localeIndependentPageUrlParts.splice(1, 1); // remove country
      }

      // TODO: fragment missing, url parameters are already in the default url field
      // TODO: kbDataLayer.page.type is this really needed? can this be realized with a filter on url in the analysis?

      const pageIdentifier = document.location.host + localeIndependentPageUrlParts.join('/');

      window.kbDataLayer = {
        page: {
          name: pageIdentifier,
          title: page.title,
          language: page.currentLanguage,
          country: pageCountry,
        },
      };

      const searchParams = new URLSearchParams(document.location.search);
      if (searchParams.has('campaign') && searchParams.has('medium') ) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const name = searchParams.get('campaign')!;
         window.kbDataLayer.campaign = {
          id: `wt_mc%3Dall.${searchParams.get('medium')}.${name}`,
          name: name,
         }
      }

      this.trackingRequest$.next(['send', 'pageupdate']);
    });
  }

}
